(function () {
  'use strict';

  angular
      .module('neo.home.phases.phase')
      .config(config);

  function config($stateProvider) {
    $stateProvider
        .state('home.phases.phase', {
          url: '/{phaseId}',
          views: {
            '': {
              templateUrl: 'home/phases/phase/phase.tpl.html',
              controller: 'PhaseCtrl',
              controllerAs: 'vm'
            },
            'actions@home.phases.phase': {
              templateUrl: 'components/actionButton/action-button.tpl.html',
              controller: 'ActionButtonCtrl',
              controllerAs: 'vm'
            }
          },
          resolve: {
            phase: function ($stateParams, Phase) {
              return Phase.query({
                phaseId: $stateParams.phaseId
              }).$promise;
            }
          }
        });
  }
}());
